import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm/LoginForm';
import CodePage from './components/CodePage/CodePage';
import IframePage from './components/IframePage/IframePage';
import UserDocumentList from "./components/UserDocuments/index";
import SnackBarComponent from './components/snackBar';
import Client from './components/client';
import { useToken } from './context/TokenContext';
import './App.css';
import LayoutComponent from './components/layout/layout';
import Dashboard from './components/Dashboard';
import Analytics from './components/Analytics';

const ProtectedRoute: React.FC<{ element: JSX.Element, redirectTo: string }> = ({ element, redirectTo }) => {
  const { token } = useToken();
  return token ? element : <Navigate to={redirectTo} replace />;
};

const App: React.FC = () => {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/code" element={<CodePage />} />
        <Route path="/test" element={<UserDocumentList userId="1" isModalSelection={false} isCompactMode={false} />} />
        <Route path="/iframe" element={<ProtectedRoute element={<IframePage />} redirectTo="/" />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<LayoutComponent><Dashboard /></LayoutComponent>} redirectTo="/" />} />
        <Route path="/analytics" element={<ProtectedRoute element={<LayoutComponent><Analytics /></LayoutComponent>} redirectTo="/" />} />
        <Route path="/client" element={<ProtectedRoute element={<LayoutComponent><Client /></LayoutComponent>} redirectTo="/" />} />        
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
    <SnackBarComponent />
    </>
  );
};

export default App;
