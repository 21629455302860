import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

const Dashboard: React.FC = () => {
  return (
    <>
      <Box className="lawyer_header_container">
        <Box className="add_client_btn_container">
          <Typography sx={{ fontSize: "26px", fontWeight: "700", minWidth: "60%" }}>
            Dashboard
          </Typography>
        </Box>
      </Box>
      <Card>
        <CardContent className="text-center">
          <div style={{ width: "100%", textAlign: "center", margin: "45px auto" }}>
            <Typography sx={{ fontSize: "26px", fontWeight: "700", minWidth: "60%" }}>
              COMING SOON...
            </Typography>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
export default Dashboard;
