import React from "react";
import { Link } from "react-router-dom";

export default function FooterComponent() {
  return (
    <div className="footer-line text-sm text-[#9CA3AF]">
        © 2024 Legal Case App : Lawyer App | All rights reserved
    </div>
  );
}
