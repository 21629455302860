import { Box } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { anonymousUser } from "../../helper/service";
import Signouticon from "../../assets/images/Signouticon.svg";
import MenuItems from "./menuItems";
import TruncatedText from "../TruncatedText/TruncatedText";

export default function SidebarComponent({
  setIsSidebarVisible,
  logoUrl,
  loading,
  handleLogout,
  isMobile,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const lawyerData: any = JSON.parse(localStorage.getItem("lawyerData") || "{}");

  return (
    <aside
      id="sidebar-multi-level-sidebar"
      className={`fixed top-0 ${
        isMobile ? "mt-[56px]" : ""
      } left-0 z-[1200] bg-[#111827] h-full transition-transform sm:translate-x-0`}
      aria-label="Sidebar"
    >
      {/* Sidebar Logo */}
      {!isMobile && (
        <Box className="sidebar-logo py-4 px-4">
          {!loading && logoUrl && (
            <img src={logoUrl} alt="Logo" className="w-full max-h-[40px] object-contain" />
          )}
        </Box>
      )}

      {/* Sidebar Menu */}
      <div className="nav-list px-2 overflow-y-auto custom-scroll">
        <ul className="space-y-2 font-medium">
          {MenuItems.map((item: any, index: number) => (
            <li key={index} className="sidebar-list">
              <NavLink
                to={item.to}
                onClick={() => {
                  navigate(item.to);
                  setIsSidebarVisible(false); // Close sidebar on mobile
                }}
                className={`flex items-center px-3 py-2.5 text-white rounded-lg group ${
                  decodeURI(location.pathname) === item.to
                    ? "bg-gray-800 text-white"
                    : "hover:bg-gray-700 hover:text-white"
                }`}
              >
                <img
                  src={item.icon}
                  alt={item.label}
                  className={`h-5 mr-3 ${
                    decodeURI(location.pathname) === item.to ? "opacity-100" : "opacity-50"
                  }`}
                />
                <span className="text-sm text-[#6B7280]">{item.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      {/* Logout Section */}
      <div
        className="fixed bottom-0bg-[#111827] px-4 py-3 border-t border-gray-800"
        style={{ width: "100%" }}
      >
        {anonymousUser() && (
          <div>
            <p className="text-sm text-[#6B7280] mb-2">
              <TruncatedText text={lawyerData?.user?.email} maxWidth={208} />
            </p>
            <div
              className="flex items-center px-3 py-2.5 text-white rounded-lg hover:bg-gray-700 hover:text-white cursor-pointer"
              onClick={handleLogout}
            >
              <img src={Signouticon} alt="Signout" className="h-5 mr-3" />
              <span className="text-sm text-[#6B7280]">Sign Out</span>
            </div>
          </div>
        )}
      </div>
    </aside>
  );
}
