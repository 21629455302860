import React from 'react';
import { Box, Typography } from '@mui/material';
import EmptyStateImage from '../../assets/images/empty-state-illustration.gif'; // Adjust the path if needed

const EmptyState = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      textAlign="center"
      sx={{ mt: 4, color: "#757575", px: 2 }}
    >
      <img
        src={EmptyStateImage}
        alt="No Data Available"
        style={{
          width: '500px', 
          height: 'auto', 
          marginBottom: '32px' 
        }}
      />
      <Typography
        variant="h5"
        id={"no_clients_found"}
        sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}
      >
        No Clients Found
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 4, color: '#666' }}
      >
        It looks like you don’t have any clients added yet. Start by adding clients to your list.
      </Typography>
    </Box>
  );
};

export default EmptyState;
