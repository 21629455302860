import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useAppDispatch } from "../../store/store";
import { addUserCaseState, updateUserCaseState } from "../../store/thunk/formThunk";
import LoadingOverlay from "../Loader/LoadingOvelay";
import TruncatedText from "../TruncatedText/TruncatedText";

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

export default function ManageCaseState({ name, client, clients, setClients, options = [] }: any) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [debounce, setDebounce] = useState(false);
  const [caseState, setCaseState] = useState<any>({
    caseId: client?.caseId,
    userId: client?.id,
    [name]: client?.caseState?.[name] || "",
  });
  useEffect(() => {
    const timeoutDuration = name === "notes" ? 1000 : 0;
    const debouceState = setTimeout(() => {
      if (debounce) {
        setIsLoading(true);
        dispatch(
          client?.caseState
            ? updateUserCaseState({
                ...client?.caseState,
                ...caseState,
              })
            : addUserCaseState({ ...caseState })
        ).then(({ payload }: any) => {
          setIsLoading(false);
          setClients(
            clients.map((client: any) => {
              if (client?.id === payload?.clientUserId && client?.caseId === payload?.caseId) {
                return {
                  ...client,
                  caseState: { ...client.caseState, ...payload },
                };
              }
              return client;
            })
          );
        });
      }
      setDebounce(false);
    }, timeoutDuration);
    return () => {
      clearTimeout(debouceState);
    };
  }, [caseState]);

  const onChange = ({ target: { name, value } }: any) => {
    setDebounce(true);
    setCaseState((prev: any) => ({ ...prev, [name]: value }));
  };
  if (name === "notes") {
    return (
      <>
        {isLoading && <LoadingOverlay text={`Saving your changes...`} />}

        <TextField
          value={caseState[name] || ""}
          id={`${client?.id}_${client?.caseId}_${name}`}
          {...{ name, onChange }}
          size="small"
          sx={{
            width: "200px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 0, // No border initially
            },
            "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderWidth: 1, // Show border on hover/focus
              },
            "& textarea": {
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#ececec",
              },
            },
          }}
          placeholder="Notes"
          multiline
          maxRows={4}
        />
      </>
    );
  }
  return (
    <div>
      {isLoading && <LoadingOverlay text={`Saving your changes...`} />}
      <FormControl sx={{ width: 130, border: 0 }}>
        <MuiSelect
          id={`${client?.id}_${client?.caseId}_${name}`}
          sx={{
            "& svg": {
              right: 0,
            },
            "& > div": {
              p: "2px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 0,
            },
            "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderWidth: 1, // Show border only on hover
              },
            "& .MuiSelect-icon": {
              display: "none", // Hide dropdown icon initially
            },
            "&:hover .MuiSelect-icon, &.Mui-focused .MuiSelect-icon": {
              display: "block", // Show dropdown icon on hover or focus
            },
          }}
          size="small"
          value={caseState[name] || ""}
          {...{ name, onChange }}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected) {
              return <em style={{ color: "#ccc" }}>Select</em>;
            }

            return (
              <Box
                sx={{
                  width: "max-content",
                  padding: name === "state" ? "2px 8px 0" : "",
                  height: 28,
                  borderRadius: "40px",

                  color: (theme) => {
                    const index = options?.findIndex((option: any) => option === caseState[name]);

                    const colors = [
                      theme.palette.warning.main,
                      theme.palette.primary.main,
                      theme.palette.secondary.main,
                      theme.palette.success.main,
                      theme.palette.error.main,
                      theme.palette.info.main,
                    ];
                    if (name !== "state") return "inherit";
                    return index !== -1
                      ? colors[index] || theme.palette.grey[500]
                      : theme.palette.grey[500];
                  },
                  bgcolor: (theme) => {
                    const index = options?.findIndex((option: any) => option === caseState[name]);

                    const colors = [
                      `${theme.palette.warning.main}20`,
                      `${theme.palette.primary.main}20`,
                      `${theme.palette.secondary.main}20`,
                      `${theme.palette.success.main}20`,
                      `${theme.palette.error.main}20`,
                      `${theme.palette.info.main}20`,
                    ];
                    if (name !== "state") return "none";
                    return index !== -1
                      ? colors[index] || `${theme.palette.grey[500]}20`
                      : `${theme.palette.grey[500]}20`;
                  },
                }}
              >
                <TruncatedText
                  text={selected}
                  maxWidth={92}
                  sx={{ fontSize: name === "state" ? "13px" : "inherit" }}
                />
              </Box>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>Select</em>
          </MenuItem>
          {options.map((option: any) => (
            <MenuItem key={option?.replace(" ", "")} value={option}>
              {option}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </div>
  );
}
