import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, notificationFail, notificationSuccess } from "../slices/notificationSlice";
import { setAllFormData } from "../slices/flowFormSlice";
import apiClient from "../../config/apiClient";

export const getAllForm: any = createAsyncThunk("getAllForm", async (_request, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response = await apiClient().get(`flows/`);
    dispatch(setLoading(false));

    if (response?.data) {
      dispatch(setAllFormData(response?.data?.data));
      // dispatch(notificationSuccess("Get form Data "));
    } else {
      dispatch(notificationFail(response?.data?.message || "Something went wrong"));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(notificationFail("Something went wrong"));
  }
});
export const updateUserCaseState: any = createAsyncThunk(
  "updateUserCaseState",
  async ({ userId, caseId, ..._restPayload }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiClient().put(`/user/${userId}/cases/${caseId}/state`, {
        ..._restPayload,
        caseId,
        clientUserId: userId,
      });
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(notificationSuccess(`Changes saved successfully!`));
        return response?.data;
      } else {
        dispatch(notificationFail(response?.data?.message || "Something went wrong"));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const addUserCaseState: any = createAsyncThunk(
  "addUserCaseState",
  async ({ userId, caseId, ..._restPayload }: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiClient().post(`/user/${userId}/cases/${caseId}/state`, {
        ..._restPayload,
        caseId,
        clientUserId: userId,
      });
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(notificationSuccess(`Changes saved successfully!`));
        return response?.data;
      } else {
        dispatch(notificationFail(response?.data?.message || "Something went wrong"));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
