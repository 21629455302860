import React, { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClientModel from "./client";
import { useToken } from "../../context/TokenContext";
import { useLocation, useNavigate } from "react-router-dom";
import ViewModal from "./viewModal";
import FilterableColumnTable from "../../components/filterableColumnTable";
import dotnetApiClient from "../../config/dotnetApiClient";
import { logErrorFunction } from "../../helper/service";

const Client: React.FC = () => {
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingClients, setLoadingClients] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [apiCalled, setApiCalled] = useState(false);
  const colorCode = ["#e5a4dd", "#f7a24b", "#fbfd52", "#009ccc"];
  const billingEntityId =
    location.state?.billingEntityId || localStorage.getItem("billingEntityId") || "";

  useEffect(() => {
    if (billingEntityId) {
      localStorage.setItem("billingEntityId", billingEntityId);
    }
  }, [billingEntityId]);

  function getRandomColor(colors: any) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  useEffect(() => {
    if (logoUrl && window) {
      window.postMessage({ type: "UPDATE_LOGO", logoUrl }, "*");
    }
  }, [logoUrl]);

  const fetchDataFromAPI = async () => {
    if (!token) {
      logErrorFunction("Token is not available");
      return;
    }

    setLoading(true);
    try {
      const response = await dotnetApiClient().get("/clients");
      const data = response.data;
      let cleintTableData: any = [];
      Promise.all(
        data.map((item: any) => {
          const arr = { ...item, backgroundColor: getRandomColor(colorCode) };
          cleintTableData.push(arr);
        })
      );
      setClients(cleintTableData);
      setApiCalled(true);
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
        logErrorFunction("Error fetching clients:" + error);
      }
    } finally {
      setLoading(false);
      setLoadingClients(false);
    }
  };

  const fetchLogoForLawyer = async () => {
    if (!token) {
      logErrorFunction("Token is not available");
      return;
    }

    setLoading(true);
    try {
      const response: any = await dotnetApiClient().get("/Billing/logo", {
        responseType: "blob",
      });
      const data = response.data;
      const blob = new Blob([data], { type: data.type });
      const url = URL.createObjectURL(blob);

      setLogoUrl(url);
    } catch (error: any) {
      logErrorFunction("Error fetching logo:" + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!apiCalled) {
      fetchDataFromAPI();
      fetchLogoForLawyer();
    }
  }, [apiCalled]);

  const handleAddClient = (newClient: ClientModel) => {
    setClients((prevClients) => [...prevClients, newClient]);
    fetchDataFromAPI();
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      {/* <Box sx={{ pt: "30px" }}> */}
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "26px", fontWeight: "700" }}>Client List</Typography>
          <Button
            variant="outlined"
            sx={{
              background: "#eff6ff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: { xs: 1, sm: "8px 16px" },
              "& .MuiButton-startIcon": {
                margin: { xs: "0", sm: "0 0 0 8px" },
              },
            }}
            onClick={() => setShowAddClientModal(true)}
            id="add_new_client_btn"
            startIcon={<AddIcon />}
          >
            <Typography
              sx={{
                display: { xs: "none", sm: "inline" },
                fontSize: "0.875rem",
                fontWeight: "500",
              }}
            >
              Add New Client
            </Typography>
          </Button>
        </Box>
      </Box>
      {loadingClients ? (
        <div className="theme-loading">
          <CircularProgress size={28} />
        </div>
      ) : (
        <FilterableColumnTable
          setClients={setClients}
          clients={clients}
          setShowAddClientModal={setShowAddClientModal}
          handleAddClient={handleAddClient}
          fetchDataFromAPI={fetchDataFromAPI}
          setApiCalled={setApiCalled}
          showAddClientModal={showAddClientModal}
        />
      )}
      {/* </Box> */}

      {showViewModal && <ViewModal isViewPopup={showViewModal} setIsView={setShowViewModal} />}
    </>
  );
};

export default Client;
