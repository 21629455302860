import DashboardIcons from "../../assets/images/dashboard-icon.svg";
import AnalyticsIcons from "../../assets/images/analytics-icon.svg";
import ClientIcons from "../../assets/images/client-icon.svg";

type MenuType = {
  label: string;
  icon: string;
  iconClosed?: JSX.Element;
  iconOpened?: JSX.Element;
  to?: string;
  menuId?: string;
  subMenu?: MenuType[];
};
const MenuItems: MenuType[] = [
  {
    label: "Dashboard",
    icon: DashboardIcons,
    to: "/dashboard",
  },
  {
    label: "Analytics",
    icon: AnalyticsIcons,
    to: "/analytics",
  },
  {
    label: "Client",
    icon: ClientIcons,
    to: "/client",
  },
];

export default MenuItems;

export const findMenuNameFromUrl = (url: string, menuItems: MenuType[] = MenuItems,): string => {
  for (const menu of menuItems) {
    if (menu.to === url) return menu.label;
    if (menu?.subMenu?.length) {
      const name: string | undefined = findMenuNameFromUrl(url, menu.subMenu);
      if (name) return name;
    }
  }
  return "";
}
