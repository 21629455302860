import React, { useRef, useState, useEffect } from "react";
import { Tooltip, Typography, Box } from "@mui/material";

const TruncatedText = ({ text = "", maxWidth = 100, sx }: any) => {
  const textRef = useRef<any>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth);
      }
    };

    checkOverflow(); // Initial check
    window.addEventListener("resize", checkOverflow); // Check on resize
    return () => window.removeEventListener("resize", checkOverflow);
  }, [text]);

  return (
    <Tooltip title={isOverflowed ? text : ""} disableHoverListener={!isOverflowed}>
      <Box
        sx={{
          maxWidth,
          display: "inline-block",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: "pointer",
        }}
      >
        <Typography ref={textRef} variant="inherit" noWrap sx={sx}>
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default TruncatedText;
