import axios from "axios";
import Config from "../config/config";

const baseURL = window.API_URL; //Config.API_URL;

const apiClient = (isFormData = false, allowAuth = true) => {
  const headers: any = {
    "Access-Control-Allow-Origin": "*",
  };

  if (isFormData) {
    Object.assign(headers, { "content-type": "multipart/form-data" });
  }

  if (allowAuth) {
    headers["Authorization"] = `Bearer ${window.localStorage.getItem("token")}`;
  }

  let instance = axios.create({
    baseURL,
    withCredentials: false,
    headers,
  });

  instance.interceptors.response.use(
    function (response) {
      if (response?.headers["token"]) {
        localStorage.setItem("accessToken", response?.headers["token"]);
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};
export default apiClient;
